export function isInjectableComponentObject(object) {
    return object.hasOwnProperty('componentTagName');
}
export function isInjectableTemplateObject(object) {
    return object.hasOwnProperty('template');
}
export function isAllowClosingModalView(object) {
    return (object.hasOwnProperty('onAllowClosingOfModal') && typeof object.onAllowClosingOfModal == 'function');
}
export var RedirectPermission;
(function (RedirectPermission) {
    RedirectPermission[RedirectPermission["Deny"] = 0] = "Deny";
    RedirectPermission[RedirectPermission["Allow"] = 1] = "Allow";
})(RedirectPermission || (RedirectPermission = {}));
export var AllowClosingOfModalResult;
(function (AllowClosingOfModalResult) {
    /**
     * Allow the modal to be closed (default behaviour if no callback is provided)
     */
    AllowClosingOfModalResult[AllowClosingOfModalResult["Allow"] = 0] = "Allow";
    /**
     * Inform the user of why it can't be closed.
     * Message is provided as the second element of the returned tuple.
     */
    AllowClosingOfModalResult[AllowClosingOfModalResult["InformMessage"] = 1] = "InformMessage";
    /**
     * Ask the user if he wants to close the modal.
     * Message is provided as the second element of the returned tuple.
     */
    AllowClosingOfModalResult[AllowClosingOfModalResult["ConfirmMessage"] = 2] = "ConfirmMessage";
})(AllowClosingOfModalResult || (AllowClosingOfModalResult = {}));
