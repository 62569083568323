import { CerumReduxSubStore } from './CerumReduxSubStore';
class CerumOrderAssignerStoreLayout {
}
export class CerumOrderAssignerStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumOrderAssignerStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumOrderAssignerStore());
    }
    static path(property) {
        return this.staticPath('CerumOrderAssignerStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumOrderAssignerStoreLayout(), action) => {
                switch (action.type) {
                    case CerumOrderAssignerStore.Actions.SET_ORDER_ASSIGNER_DATA:
                        return Object.assign(Object.assign({}, state), { orderAssignerData: action.payload });
                }
                return state;
            },
        };
    }
}
(function (CerumOrderAssignerStore) {
    let Actions;
    (function (Actions) {
        Actions.SET_ORDER_ASSIGNER_DATA = 'SET_ORDER_ASSIGNER_DATA';
    })(Actions = CerumOrderAssignerStore.Actions || (CerumOrderAssignerStore.Actions = {}));
    function setOrderAssignerData(orderAssignerData) {
        return {
            type: Actions.SET_ORDER_ASSIGNER_DATA,
            payload: orderAssignerData,
        };
    }
    CerumOrderAssignerStore.setOrderAssignerData = setOrderAssignerData;
})(CerumOrderAssignerStore || (CerumOrderAssignerStore = {}));
