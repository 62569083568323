var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CerumLoading } from '../CerumLoading';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { isErrorHTTP } from 'base-api/APIBase';
let DocumentViewer = class DocumentViewer extends LitElement {
    constructor() {
        super(...arguments);
        this.showDownload = false;
        this.source = '';
        this.blobUrl = '';
        this.fileName = '';
    }
    static get is() {
        return 'document-viewer';
    }
    static get styles() {
        return css `
            #container {
                height: var(--document-viewer-height, calc(100vh - 200px));
            }

            iframe {
                width: 100%;
                height: 100%;
                border: 1px solid #00000082;
                border-radius: 4px;
            }

            @media screen and (max-width: 900px) {
                p {
                    font-size: 16px;
                }

                a {
                    font-size: 16px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div id="container">
                <div style="display: flex; justify-content: end;">
                    <a href="javascript:void(0);" @click="${this._downloadFile}">
                        Klikk her for å laste ned
                    </a>
                </div>
                ${this.source
            ? html `<iframe
                          src="${this.source}"
                          @load="${(event) => URL.revokeObjectURL(event.target.src)}"
                          @error="${(event) => URL.revokeObjectURL(event.target.src)}"
                      ></iframe>`
            : html `<cerum-loading title="Laster inn dokument..."></cerum-loading>`}
            </div>
        `;
    }
    _resolveDocumentUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (this.documentUrl instanceof Promise) {
                const sourceResult = yield this.documentUrl;
                if (isErrorHTTP(sourceResult)) {
                    return;
                }
                this.source = sourceResult;
            }
            else {
                this.source = (_a = this.documentUrl) !== null && _a !== void 0 ? _a : '';
            }
            yield this._fetchBlob();
        });
    }
    _fetchBlob() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const response = yield fetch(this.source, {
                credentials: 'include',
            });
            if (!response.ok) {
                return;
            }
            const blob = yield response.blob();
            this.fileName = (_b = (_a = this.documentMetadata) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : 'dokument';
            if (this.blobUrl) {
                URL.revokeObjectURL(this.blobUrl);
            }
            this.blobUrl = URL.createObjectURL(blob);
        });
    }
    _downloadFile() {
        if (!this.blobUrl) {
            return;
        }
        const link = document.createElement('a');
        link.href = this.blobUrl;
        link.download = this.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        URL.revokeObjectURL(this.blobUrl);
        URL.revokeObjectURL(this.source);
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'documentUrl':
                    this._resolveDocumentUrl();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
DocumentViewer._deps = [CerumLoading];
__decorate([
    state(),
    __metadata("design:type", Object)
], DocumentViewer.prototype, "documentUrl", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], DocumentViewer.prototype, "documentMetadata", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], DocumentViewer.prototype, "showDownload", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], DocumentViewer.prototype, "source", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], DocumentViewer.prototype, "blobUrl", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], DocumentViewer.prototype, "fileName", void 0);
DocumentViewer = __decorate([
    customElement(DocumentViewer.is)
], DocumentViewer);
export { DocumentViewer };
