import { Checkbox } from '@vaadin/checkbox';
import { CheckboxGroup } from '@vaadin/checkbox-group';
import { RadioGroup } from '@vaadin/radio-group';
import { noChange } from 'lit';
import { Directive, directive } from 'lit/directive.js';
import { CerumArticleLookup } from './CerumArticleLookup';
import { ComboBox } from '@vaadin/combo-box';
import { CerumFormHelper } from './helpers/CerumFormHelper';
import { MultiSelectComboBox } from '@vaadin/multi-select-combo-box';
let validationFields = [];
class ValidateDirective extends Directive {
    reconnected() {
        validationFields = [];
    }
    update(part, [schemaName]) {
        this.validationField = part.element;
        if (!validationFields.find((field) => { var _a; return field.schema === ((_a = this.validationField) === null || _a === void 0 ? void 0 : _a.schema); })) {
            validationFields.push(this.validationField);
            this.validationField.setAttribute('data-testid', schemaName);
        }
        return this.render(schemaName);
    }
    render(schemaName) {
        if (!this.validationField) {
            return noChange;
        }
        if (!this.validationField.schema) {
            this.validationField.schema = schemaName;
        }
        return noChange;
    }
}
const validateDirective = directive(ValidateDirective);
export class CerumFormBuilder {
    constructor(host) {
        this.schema = {};
        this.invalidFields = [];
        this.validateValues = [];
        this.isDirty = false;
        (this.host = host).addController(this);
    }
    hostDisconnected() {
        this.reset();
    }
    hostConnected() {
        CerumFormHelper.instance().formBuilder = this;
    }
    reconnected() {
        CerumFormHelper.instance().formBuilder = this;
    }
    reset() {
        this.schema = {};
        this.invalidFields = [];
        this.validationField = undefined;
        this.validateValues = [];
        this.isDirty = false;
        validationFields = [];
    }
    setValidation(schemaName) {
        return validateDirective(schemaName);
    }
    validate() {
        const schemas = Object.entries(this.schema);
        let isValid = false;
        this.invalidFields = [];
        this.validateValues = [];
        for (const [schemaKey, schemaConfig] of schemas) {
            this.validationField = validationFields.find((validationField) => { var _a; return ((_a = validationField.schema) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === schemaKey.toLowerCase(); });
            if (!this.validationField) {
                console.error('validation field not found', schemaKey);
                continue;
            }
            this._addErrorMessagesToFields(schemaConfig, this.validationField);
            const { expect } = schemaConfig;
            if (!schemaConfig.customValidation) {
                if (this._isCheckbox(this.validationField)) {
                    const [checked, value] = this._validateCheckbox(this.validationField);
                    if (value) {
                        this.isDirty = true;
                    }
                    isValid = this._checkExpected(expect, checked);
                }
                else if (this._isRadioGroup(this.validationField)) {
                    const [_validated, value] = this._validateRadioGroup(this.validationField);
                    if (value) {
                        this.isDirty = true;
                    }
                    isValid = this._checkExpected(expect, value);
                }
                else if (this._isArticleLookup(this.validationField)) {
                    const [validated, value] = this._validateArticleLookup(this.validationField);
                    if (value !== -1) {
                        this.isDirty = true;
                    }
                    isValid = this._checkExpected(expect, validated);
                }
                else {
                    // usikker på om eg burde bruk denne, eller om det burde være en typ fallback ich greie
                    //this.validationField.validate();
                    if (this.validationField.value) {
                        this.isDirty = true;
                    }
                    isValid = this._checkExpected(expect, this.validationField.value);
                }
            }
            else {
                const [validation, value] = schemaConfig.customValidation();
                isValid = validation;
                this.isDirty = Boolean(value);
            }
            this._setInvalidState(isValid);
            this._setInvalidField(schemaConfig, isValid);
            this.validateValues.push(isValid);
        }
        if (this.invalidFields.length) {
            console.error('validation failed', this.invalidFields);
        }
        const validation = this.validateValues.every((value) => value);
        if (validation) {
            if (!this.host.getAttribute('is-valid')) {
                this.host.setAttribute('is-valid', validation.toString());
            }
        }
        else {
            this.host.removeAttribute('is-valid');
        }
        this.host.requestUpdate();
        return validation;
    }
    _setInvalidState(isValid) {
        var _a, _b;
        if (!this.validationField) {
            return;
        }
        if (!isValid) {
            if (this._isCheckbox(this.validationField)) {
                const parent = this.validationField.parentElement;
                if (this._checkboxHasParent(parent)) {
                    parent.invalid = true;
                }
            }
            else if (this._isArticleLookup(this.validationField)) {
                (_b = (_a = this.validationField.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.validate')) === null || _b === void 0 ? void 0 : _b.setAttribute('invalid', '');
            }
            else {
                this.validationField.invalid = true;
            }
        }
        else {
            this.validationField.removeAttribute('invalid');
        }
    }
    _isRadioGroup(validationField) {
        return validationField instanceof RadioGroup;
    }
    _isCheckbox(validationField) {
        return validationField instanceof Checkbox;
    }
    _isCombobox(validationField) {
        return validationField instanceof ComboBox;
    }
    _isMultiSelectComboBox(validationField) {
        return validationField instanceof MultiSelectComboBox;
    }
    _isArticleLookup(validationField) {
        return validationField instanceof CerumArticleLookup;
    }
    _checkboxHasParent(validationParent) {
        if (!validationParent) {
            return false;
        }
        return Boolean('parentElement' in validationParent && validationParent instanceof CheckboxGroup);
    }
    _setInvalidField(schemaConfig, isValid) {
        if (!this.validationField || !schemaConfig.invalidTitle) {
            return;
        }
        if (isValid || this.invalidFields.includes(this.validationField)) {
            return;
        }
        const field = this.validationField;
        field.invalidTitle = schemaConfig.invalidTitle;
        this.invalidFields.push(field);
    }
    _checkExpected(expect, value) {
        let validated = false;
        switch (expect) {
            case 'filled':
            case 'empty':
                const expectToBool = Boolean(expect === 'filled');
                if (Array.isArray(value)) {
                    validated = Boolean(value.length) === expectToBool;
                }
                else if (typeof value === 'string') {
                    validated = this.isTruthyString(value) === expectToBool;
                }
                else {
                    validated = Boolean(value) === expectToBool;
                }
                break;
            case true:
            case false:
                if (Array.isArray(value)) {
                    return Boolean(value.length) === expect;
                }
                else if (typeof value === 'string') {
                    return Boolean(value) === expect;
                }
                validated = value === expect;
        }
        return validated;
    }
    isTruthyString(value) {
        return value !== '' && value !== '0';
    }
    _addErrorMessagesToFields(schemaConfig, validationField) {
        if (schemaConfig.errorMessage && !validationField.getAttribute('error-message')) {
            const isCheckbox = this._isCheckbox(validationField);
            const isArticleLookup = this._isArticleLookup(validationField);
            const parent = validationField.parentElement;
            const isCheckboxInGroup = this._checkboxHasParent(parent);
            if (isCheckbox) {
                if (isCheckboxInGroup) {
                    parent.setAttribute('error-message', schemaConfig.errorMessage);
                }
                else {
                    console.error('Checkbox does not support error messages');
                }
            }
            else if (isArticleLookup) {
                if (!validationField.shadowRoot) {
                    return;
                }
                const children = validationField.shadowRoot.children;
                for (const child of Array.from(children)) {
                    if (this._isMultiSelectComboBox(child) || this._isCombobox(child)) {
                        child.setAttribute('error-message', schemaConfig.errorMessage);
                    }
                }
            }
            else {
                validationField.setAttribute('error-message', schemaConfig.errorMessage);
            }
        }
    }
    _validateRadioGroup(validationField) {
        var _a;
        return [validationField.validate(), (_a = validationField.value) !== null && _a !== void 0 ? _a : ''];
    }
    _validateArticleLookup(validationField) {
        return [
            validationField.validateSelectedArticle(),
            validationField.getSelectedRequiredArticle() ? 1 : -1,
        ];
    }
    _validateCheckbox(validationField) {
        const parent = validationField.parentElement;
        if (parent instanceof CheckboxGroup) {
            return [parent.validate(), parent.value];
        }
        return [validationField.checked, validationField.value];
    }
}
