import { BoostPortalSubStore } from './BoostPortalSubStore';
import { ewNewMemberInfoResult } from 'boost-api/schemas/ewNewMemberInfoResult';
class BoostPortalStoreLayout {
    constructor() {
        this.colors = [];
        this.departments = [];
        this.memberInformation = new ewNewMemberInfoResult();
        this.memberships = [];
        this.sponsorCode = '';
        this.availableDepartments = [];
        this.images = [];
    }
}
export class BoostPortalStore extends BoostPortalSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'BoostPortalStore';
    }
    static get instance() {
        return this._instance || (this._instance = new BoostPortalStore());
    }
    static path(property) {
        return this.staticPath('BoostPortalStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new BoostPortalStoreLayout(), action) => {
                switch (action.type) {
                    case BoostPortalStore.Actions.SET_SERVICE_URL:
                        return Object.assign(Object.assign({}, state), { serviceUrl: action.payload.serviceUrl });
                    case BoostPortalStore.Actions.SET_CENTER:
                        return Object.assign(Object.assign({}, state), { center: action.payload.center });
                    case BoostPortalStore.Actions.SET_CENTER_SHORT:
                        return Object.assign(Object.assign({}, state), { centerShort: action.payload.centerShort });
                    case BoostPortalStore.Actions.SET_SELECTED_DEPARTMENT_ID:
                        return Object.assign(Object.assign({}, state), { selectedDepartmentId: action.payload.selectedDepartmentId });
                    case BoostPortalStore.Actions.SET_SELECTED_MEMBERSHIP_ID:
                        return Object.assign(Object.assign({}, state), { selectedMembershipId: action.payload.selectedMembershipId });
                    case BoostPortalStore.Actions.SET_DEPARTMENTS:
                        return Object.assign(Object.assign({}, state), { departments: action.payload.departments });
                    case BoostPortalStore.Actions.SET_MEMBERSHIPS:
                        return Object.assign(Object.assign({}, state), { memberships: action.payload.memberships });
                    case BoostPortalStore.Actions.SET_MEMBER_INFORMATION:
                        return Object.assign(Object.assign({}, state), { memberInformation: Object.assign({}, action.payload.memberInformation) });
                    case BoostPortalStore.Actions.SET_SPONSOR_CODE:
                        return Object.assign(Object.assign({}, state), { sponsorCode: action.payload.sponsorCode });
                    case BoostPortalStore.Actions.SET_TOKEN:
                        return Object.assign(Object.assign({}, state), { token: action.payload.token });
                    case BoostPortalStore.Actions.SET_COLORS:
                        return Object.assign(Object.assign({}, state), { colors: action.payload.colors });
                    case BoostPortalStore.Actions.SET_AVAILABLE_DEPARTMENTS:
                        return Object.assign(Object.assign({}, state), { availableDepartments: action.payload.availableDepartments });
                    case BoostPortalStore.Actions.SET_IMAGES:
                        return Object.assign(Object.assign({}, state), { images: action.payload.images });
                    case BoostPortalStore.Actions.SET_SESSION:
                        return Object.assign(Object.assign({}, state), { session: action.payload.session });
                    case BoostPortalStore.Actions.SET_LOGGED_IN_PROFILE:
                        return Object.assign(Object.assign({}, state), { loggedInProfile: action.payload.loggedInProfile });
                    case BoostPortalStore.Actions.SET_PAYMENT_CONDITION:
                        return Object.assign(Object.assign({}, state), { paymentCondition: action.payload.paymentCondition });
                    case BoostPortalStore.Actions.SET_SPONSOR_SETUP:
                        return Object.assign(Object.assign({}, state), { isSponsorSetup: action.payload.isSponsorSetup });
                    case BoostPortalStore.Actions.SET_IS_DIRECT_LINK:
                        return Object.assign(Object.assign({}, state), { isDirectLink: action.payload.isDirectLink });
                }
                return state;
            },
        };
    }
}
(function (BoostPortalStore) {
    let Actions;
    (function (Actions) {
        Actions.SET_SERVICE_URL = 'SET_SERVICE_URL';
        Actions.SET_CENTER = 'SET_CENTER';
        Actions.SET_CENTER_SHORT = 'SET_CENTER_SHORT';
        Actions.SET_SELECTED_DEPARTMENT_ID = 'SET_SELECTED_DEPARTMENT_ID';
        Actions.SET_SELECTED_MEMBERSHIP_ID = 'SET_SELECTED_MEMBERSHIP_ID';
        Actions.SET_DEPARTMENTS = 'SET_DEPARTMENTS';
        Actions.SET_MEMBERSHIPS = 'SET_MEMBERSHIPS';
        Actions.SET_MEMBER_INFORMATION = 'SET_MEMBER_INFORMATION';
        Actions.SET_SPONSOR_CODE = 'SET_SPONSOR_CODE';
        Actions.SET_TOKEN = 'SET_TOKEN';
        Actions.SET_COLORS = 'SET_COLORS';
        Actions.SET_AVAILABLE_DEPARTMENTS = 'SET_AVAILABLE_DEPARTMENTS';
        Actions.SET_IMAGES = 'SET_IMAGES';
        Actions.SET_SESSION = 'SET_SESSION';
        Actions.SET_LOGGED_IN_PROFILE = 'SET_LOGGED_IN_PROFILE';
        Actions.SET_PAYMENT_CONDITION = 'SET_PAYMENT_CONDITION';
        Actions.SET_SPONSOR_SETUP = 'SET_SPONSOR_SETUP';
        Actions.SET_IS_DIRECT_LINK = 'SET_IS_DIRECT_LINK';
        Actions.SetServiceUrl = (serviceUrl) => ({
            type: Actions.SET_SERVICE_URL,
            payload: {
                serviceUrl,
            },
        });
        Actions.SetCenter = (center) => ({
            type: Actions.SET_CENTER,
            payload: {
                center,
            },
        });
        Actions.SetCenterShort = (centerShort) => ({
            type: Actions.SET_CENTER_SHORT,
            payload: {
                centerShort,
            },
        });
        Actions.SetSelectedDepartmentId = (selectedDepartmentId) => ({
            type: Actions.SET_SELECTED_DEPARTMENT_ID,
            payload: {
                selectedDepartmentId,
            },
        });
        Actions.SetSelectedMembershipId = (selectedMembershipId) => ({
            type: Actions.SET_SELECTED_MEMBERSHIP_ID,
            payload: {
                selectedMembershipId,
            },
        });
        Actions.SetDepartments = (departments) => ({
            type: Actions.SET_DEPARTMENTS,
            payload: {
                departments,
            },
        });
        Actions.SetMemberships = (memberships) => ({
            type: Actions.SET_MEMBERSHIPS,
            payload: {
                memberships,
            },
        });
        Actions.SetMemberInformation = (memberInformation) => ({
            type: Actions.SET_MEMBER_INFORMATION,
            payload: {
                memberInformation,
            },
        });
        Actions.SetSponsorCode = (sponsorCode) => ({
            type: Actions.SET_SPONSOR_CODE,
            payload: {
                sponsorCode,
            },
        });
        Actions.SetToken = (token) => ({
            type: Actions.SET_TOKEN,
            payload: {
                token,
            },
        });
        Actions.SetColors = (colors) => ({
            type: Actions.SET_COLORS,
            payload: {
                colors,
            },
        });
        Actions.SetAvailableDepartments = (availableDepartments) => ({
            type: Actions.SET_AVAILABLE_DEPARTMENTS,
            payload: {
                availableDepartments,
            },
        });
        Actions.SetImages = (images) => ({
            type: Actions.SET_IMAGES,
            payload: {
                images,
            },
        });
        Actions.SetSession = (session) => ({
            type: Actions.SET_SESSION,
            payload: {
                session,
            },
        });
        Actions.SetLoggedInProfile = (loggedInProfile) => ({
            type: Actions.SET_LOGGED_IN_PROFILE,
            payload: {
                loggedInProfile,
            },
        });
        Actions.SetPaymentCondition = (paymentCondition) => ({
            type: Actions.SET_PAYMENT_CONDITION,
            payload: {
                paymentCondition,
            },
        });
        Actions.SetSponsorSetup = (isSponsorSetup) => ({
            type: Actions.SET_SPONSOR_SETUP,
            payload: {
                isSponsorSetup,
            },
        });
        Actions.SetIsDirectLink = (isDirectLink) => ({
            type: Actions.SET_IS_DIRECT_LINK,
            payload: {
                isDirectLink,
            },
        });
    })(Actions = BoostPortalStore.Actions || (BoostPortalStore.Actions = {}));
})(BoostPortalStore || (BoostPortalStore = {}));
