var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let CerumBadge = class CerumBadge extends LitElement {
    constructor() {
        super(...arguments);
        this.type = '';
        this.showClearButton = false;
    }
    static get is() {
        return 'cerum-badge';
    }
    static get styles() {
        return css `
            span {
                padding: 4px 8px;
                text-transform: uppercase;
                border-radius: 12px;
                font-size: var(--text-2xs);
                font-weight: var(--font-semibold);
                letter-spacing: 0.4px;
                white-space: var(--cerum-badge-white-space, nowrap);
                display: inline-flex;
                width: var(--cerum-badge-width, auto);
            }

            .primary {
                background-color: hsl(211.2, 13%, 90%) !important;
                color: hsl(211.2, 13%, 20%);
            }

            .success {
                background-color: hsl(159, 60%, 90%);
                color: hsl(159, 75%, 16%);
            }

            .success:hover {
                background-color: rgb(174, 213, 199);
                transition: 300ms ease-out;
            }

            .info {
                background-color: var(--cerum-badge-background-color, hsl(210, 60%, 90%));
                color: var(--cerum-badge-color, hsl(210, 75%, 16%));
            }

            .info:hover {
                background-color: rgb(166, 187, 206);
                transition: 300ms ease-out;
            }

            .warning {
                background-color: hsl(28, 80%, 93%);
                color: hsl(28, 74%, 24%);
            }

            .warning:hover {
                background-color: hsl(26, 49.5%, 79%);
                transition: 300ms ease-out;
            }

            .danger {
                background-color: hsl(3, 100%, 96%);
                color: hsl(360, 76%, 20%);
            }

            .danger:hover {
                background-color: rgb(236, 205, 203);
                transition: 300ms ease-out;
            }

            .neutral {
                background-color: #eee;
            }

            .neutral:hover {
                background-color: hsl(211.2, 13%, 90%);
                transition: 300ms ease-out;
            }

            .vipps {
                background-color: #ff602b;
                color: white;
            }

            .remove {
                padding-left: 10px;
            }

            .remove:hover {
                background-color: var(--cerum-red) !important;
                transition: fadein 200ms;
                cursor: pointer;
            }
        `;
    }
    /** @override */ render() {
        return html `
            <span class="${this.type} ${this.showClearButton ? 'remove' : ''}">
                <slot></slot>
                ${this.showClearButton ? html `<div class="remove">x</div>` : ''}
            </span>
        `;
    }
};
CerumBadge._deps = [];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumBadge.prototype, "type", void 0);
__decorate([
    property({ type: Boolean, attribute: 'clear-button-visible' }),
    __metadata("design:type", Object)
], CerumBadge.prototype, "showClearButton", void 0);
CerumBadge = __decorate([
    customElement(CerumBadge.is)
], CerumBadge);
export { CerumBadge };
