import { TaskStatus } from 'cerum-work-schedule-defs/TaskStatus';
import moment from 'moment';
import { EngagementStatus } from 'cerum-work-schedule-defs/AssigneeWithStatus';
import { isEngagementStatusType, isTaskStatusType, } from 'cerum-work-schedule-data/CerumWorkScheduleTaskHelper';
export class WorkSchedulePortletStoreHelper {
    _mapMetricsToArray(aggregatedMetrics) {
        const metrics = [];
        for (const key in aggregatedMetrics) {
            const aggregatedMetric = aggregatedMetrics[key];
            metrics.push(aggregatedMetric);
        }
        return metrics;
    }
    _getMetricKey(task, workhoursPerPersonWithAbsence) {
        var _a, _b;
        if (task.completion_status === TaskStatus.DELETED) {
            return TaskStatus.DELETED;
        }
        if (!task.assignee && !task.assignees.length) {
            return EngagementStatus.UNASSIGNED;
        }
        if (task.assignees.find((assignee) => assignee.status === EngagementStatus.TERMINATED)) {
            return EngagementStatus.TERMINATED;
        }
        const hasPassiveAssignees = ((_a = task.assignee) === null || _a === void 0 ? void 0 : _a.status) === EngagementStatus.PASSIVE ||
            task.assignees.find((assignee) => assignee.status === EngagementStatus.PASSIVE);
        if (hasPassiveAssignees) {
            return EngagementStatus.PASSIVE;
        }
        if (task.assignees.length) {
            let workhourDaysDateEqual;
            const taskTime = moment(task.start);
            const assigneeIds = task.assignees.map((assignee) => assignee.data_id);
            if (workhoursPerPersonWithAbsence === null || workhoursPerPersonWithAbsence === void 0 ? void 0 : workhoursPerPersonWithAbsence.length) {
                for (const personWorkhour of workhoursPerPersonWithAbsence) {
                    const days = personWorkhour.days;
                    for (let i = 0; i < days.length; ++i) {
                        const dayTime = moment.unix(days[i].date);
                        if ((taskTime.isBetween(dayTime, taskTime, 'date') &&
                            assigneeIds.includes(personWorkhour.personId)) ||
                            (taskTime.isSame(dayTime, 'date') &&
                                assigneeIds.includes(personWorkhour.personId))) {
                            workhourDaysDateEqual = days.find((day) => { var _a; return day.holiday || ((_a = day.expenses) === null || _a === void 0 ? void 0 : _a.find((expense) => expense)); });
                        }
                    }
                }
            }
            if (workhourDaysDateEqual === null || workhourDaysDateEqual === void 0 ? void 0 : workhourDaysDateEqual.holiday) {
                return TaskStatus.HOLIDAY;
            }
            else if ((_b = workhourDaysDateEqual === null || workhourDaysDateEqual === void 0 ? void 0 : workhourDaysDateEqual.expenses) === null || _b === void 0 ? void 0 : _b.length) {
                return TaskStatus.DEVIANCE;
            }
        }
        if (task.completion_status === TaskStatus.SCHEDULED) {
            return TaskStatus.INPROGRESS;
        }
        return task.completion_status;
    }
    getAggregateTaskMetrics(aggregatedData, onlyDeviations, availableAssignees, includeDuplicateTasks, deletedTasks) {
        var _a;
        const { tasks, hoursPerPerson } = aggregatedData;
        let allTasks = [...tasks];
        if (deletedTasks === null || deletedTasks === void 0 ? void 0 : deletedTasks.length) {
            allTasks = [...allTasks, ...deletedTasks];
        }
        let tasklist = this.mapTaskSummaryAssigneesToAssigneeWithStatus(allTasks, availableAssignees);
        const aggregatedMetrics = {};
        // Filter out workhours for persons that have no absence
        const workhoursPerPersonWithAbsence = hoursPerPerson === null || hoursPerPerson === void 0 ? void 0 : hoursPerPerson.filter((hours) => hours.days.find((day) => { var _a; return ((_a = day.expenses) === null || _a === void 0 ? void 0 : _a.find((expense) => expense.absense)) || day.holiday; }));
        // Filter out days that have no absence or holiday for each person in workhours,
        // done this way because original data is immutable
        const updatedWorkhoursPerPersonWithAbsence = (_a = workhoursPerPersonWithAbsence === null || workhoursPerPersonWithAbsence === void 0 ? void 0 : workhoursPerPersonWithAbsence.map((workhoursForPerson) => (Object.assign(Object.assign({}, workhoursForPerson), { days: workhoursForPerson.days.filter((day) => { var _a; return day.holiday || ((_a = day.expenses) === null || _a === void 0 ? void 0 : _a.find((expense) => expense.absense)); }) })))) !== null && _a !== void 0 ? _a : [];
        if (!includeDuplicateTasks) {
            const taskIds = tasklist.map((task) => task.id);
            tasklist = tasklist.filter(({ id, metricKey }, index) => {
                if (metricKey === TaskStatus.SIMULATED) {
                    return true;
                }
                return !taskIds.includes(id, index + 1);
            });
        }
        for (const task of tasklist) {
            if (!task.completion_status) {
                continue;
            }
            const metricKey = this._getMetricKey(task, updatedWorkhoursPerPersonWithAbsence);
            task.metricKey = metricKey;
            if (isTaskStatusType(metricKey) &&
                [TaskStatus.HOLIDAY, TaskStatus.DEVIANCE].includes(metricKey)) {
                task.extendedAbsence = {
                    reason: metricKey,
                };
            }
            if (!(metricKey in aggregatedMetrics)) {
                aggregatedMetrics[metricKey] = {
                    name: metricKey,
                    tasks: [task],
                };
            }
            else {
                const { tasks } = aggregatedMetrics[metricKey];
                aggregatedMetrics[metricKey] = Object.assign(Object.assign({}, aggregatedMetrics[metricKey]), { tasks: [...tasks, task] });
            }
        }
        const mappedMetrics = this._mapMetricsToArray(aggregatedMetrics);
        const filteredMetrics = this.filterMetrics(mappedMetrics, onlyDeviations);
        return filteredMetrics;
    }
    _getAssigneeStatus(assigneeDataId, assignees) {
        var _a;
        const assigneeMatch = assignees.find((findAssignee) => findAssignee.data_id === assigneeDataId);
        return (_a = assigneeMatch === null || assigneeMatch === void 0 ? void 0 : assigneeMatch.status) !== null && _a !== void 0 ? _a : EngagementStatus.UNASSIGNED;
    }
    mapTaskSummaryAssigneesToAssigneeWithStatus(tasks, assignees) {
        const aggregatedTasks = tasks.map((task) => {
            const assigneeWithStatus = this._mapWorkPlanningAssigneeToAssigneeWithStatus(assignees, task.assignee);
            const assigneesWithStatus = [];
            task.assignees.map((assignee) => {
                const mappedAssignee = this._mapWorkPlanningAssigneeToAssigneeWithStatus(assignees, assignee);
                if (mappedAssignee) {
                    assigneesWithStatus.push(mappedAssignee);
                }
            });
            const aggregatedTask = Object.assign(Object.assign({}, task), { assignee: assigneeWithStatus, assignees: assigneesWithStatus, metricKey: task.completion_status });
            return aggregatedTask;
        });
        return aggregatedTasks;
    }
    _mapWorkPlanningAssigneeToAssigneeWithStatus(assignees, assignee) {
        if (!assignee || !assignees.length) {
            return;
        }
        return Object.assign(Object.assign({}, assignee), { status: this._getAssigneeStatus(assignee.data_id, assignees), data_id: assignee.data_id, name: assignee.name, datatype_id: assignee.datatype_id, id: assignee.id, node_id: assignee.node_id });
    }
    filterMetrics(metrics, onlyDeviations) {
        if (onlyDeviations) {
            metrics = metrics.filter((metric) => (isTaskStatusType(metric.name) || isEngagementStatusType(metric.name)) &&
                [
                    TaskStatus.DEVIANCE,
                    TaskStatus.HOLIDAY,
                    TaskStatus.OVERDUE,
                    EngagementStatus.UNASSIGNED,
                    EngagementStatus.PASSIVE,
                    EngagementStatus.TERMINATED,
                ].includes(metric.name));
            // Remove cancelled tasks from deviations
            for (const metric of metrics) {
                metric.tasks = metric.tasks.filter((task) => task.completion_status &&
                    ![TaskStatus.CANCELLED, TaskStatus.PROPOSED].includes(task.completion_status));
            }
            return metrics;
        }
        return metrics.filter((metric) => ![TaskStatus.HOLIDAY, TaskStatus.PROPOSED].includes(metric.name));
    }
}
