var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ArticleResources } from './ArticleResources';
import { DeliveryArticle } from './DeliveryArticle';
import { DeliveryLine } from './DeliveryLine';
import { JSONStruct, array_property, nullable, property } from 'badphraim/data/JSONStruct';
export class Delivery extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.article_list = [];
        /** @override */ this.id = 0;
        /** @override */ this.lines = [];
        /** @override */ this.name = '';
        /** @override */ this.single = false;
        /** @override */ this.start_date = '';
        /** @override */ this.type = '';
    }
}
__decorate([
    nullable,
    __metadata("design:type", ArticleResources)
], Delivery.prototype, "article", void 0);
__decorate([
    array_property(DeliveryArticle),
    __metadata("design:type", Array)
], Delivery.prototype, "article_list", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], Delivery.prototype, "id", void 0);
__decorate([
    array_property(DeliveryLine),
    __metadata("design:type", Array)
], Delivery.prototype, "lines", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], Delivery.prototype, "name", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], Delivery.prototype, "notify_email", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], Delivery.prototype, "notify_phonenumber", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], Delivery.prototype, "order_id", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], Delivery.prototype, "single", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], Delivery.prototype, "start_date", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], Delivery.prototype, "stop_date", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], Delivery.prototype, "type", void 0);
