var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '@polymer/paper-progress/paper-progress';
export var LoadingType;
(function (LoadingType) {
    LoadingType["CIRCLE"] = "circle";
    LoadingType["DOT"] = "dot";
    LoadingType["ERROR"] = "loading-error";
    LoadingType["LINE"] = "line";
    LoadingType["SUCCESS"] = "loading-success";
})(LoadingType || (LoadingType = {}));
let CerumLoading = class CerumLoading extends LitElement {
    constructor() {
        super(...arguments);
        this.title = '';
        this.type = LoadingType.LINE;
    }
    static get is() {
        return 'cerum-loading';
    }
    static get styles() {
        return css `
            :host {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 20px;
            }

            div.progress-container {
                width: var(--loading-container-width, 50%);
            }

            paper-progress {
                width: 100%;
                --paper-progress-active-color: #00adbb;
            }

            .loader {
                border: 16px solid #f3f3f3; /* Light grey */
                border-top: 16px solid #3498db; /* Blue */
                border-radius: 50%;
                width: 120px;
                height: 120px;
                animation: spin 2s linear infinite;
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            .checkmark-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .checkmark-circle {
                stroke-dasharray: 166;
                stroke-dashoffset: 166;
                stroke-width: 2;
                stroke-miterlimit: 10;
                stroke: #7ac142;
                fill: none;
                animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            }

            .exclamationmark-circle {
                stroke-dasharray: 166;
                stroke-dashoffset: 166;
                stroke-width: 2;
                stroke-miterlimit: 10;
                stroke: red;
                fill: none;
                animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            }

            .checkmark {
                width: var(--checkmark-width, 56px);
                height: var(--checkmark-height, 56px);
                border-radius: 50%;
                display: block;
                stroke-width: 2;
                stroke: #fff;
                stroke-miterlimit: 10;
                margin: 10% auto;
                box-shadow: inset 0px 0px 0px #7ac142;
                animation:
                    fill 0.4s ease-in-out 0.4s forwards,
                    scale 0.3s ease-in-out 0.9s both;
            }

            .exclamationmark {
                width: var(--checkmark-width, 56px);
                height: var(--checkmark-height, 56px);
                border-radius: 50%;
                display: block;
                stroke-width: 2;
                stroke: #fff;
                stroke-miterlimit: 10;
                margin: 10% auto;
                box-shadow: inset 0px 0px 0px red;
                animation:
                    fill2 0.4s ease-in-out 0.4s forwards,
                    scale 0.3s ease-in-out 0.9s both;
            }

            .checkmark-check {
                transform-origin: 50% 50%;
                stroke-dasharray: 48;
                stroke-dashoffset: 48;
                animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            }

            .exclamationmark-line {
                transform-origin: 50% 50%;
                stroke-dasharray: 48;
                stroke-dashoffset: 48;
                animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
                stroke: white;
            }

            @keyframes stroke {
                100% {
                    stroke-dashoffset: 0;
                }
            }

            @keyframes scale {
                0%,
                100% {
                    transform: none;
                }
                50% {
                    transform: scale3d(1.1, 1.1, 1);
                }
            }

            @keyframes fill {
                100% {
                    box-shadow: inset 0px 0px 0px 60px #7ac142;
                }
            }

            @keyframes fill2 {
                100% {
                    box-shadow: inset 0px 0px 0px 60px red;
                }
            }

            .dot-loader {
                height: var(--cerum-loading-dot-size, 30px);
                aspect-ratio: 2.5;
                --_g: no-repeat radial-gradient(farthest-side, #000 90%, #0000);
                background: var(--_g), var(--_g), var(--_g), var(--_g);
                background-size: 20% 50%;
                animation: l44 1s infinite linear alternate;
            }

            @keyframes l44 {
                0%,
                5% {
                    background-position:
                        calc(0 * 100% / 3) 50%,
                        calc(1 * 100% / 3) 50%,
                        calc(2 * 100% / 3) 50%,
                        calc(3 * 100% / 3) 50%;
                }
                12.5% {
                    background-position:
                        calc(0 * 100% / 3) 0,
                        calc(1 * 100% / 3) 50%,
                        calc(2 * 100% / 3) 50%,
                        calc(3 * 100% / 3) 50%;
                }
                25% {
                    background-position:
                        calc(0 * 100% / 3) 0,
                        calc(1 * 100% / 3) 0,
                        calc(2 * 100% / 3) 50%,
                        calc(3 * 100% / 3) 50%;
                }
                37.5% {
                    background-position:
                        calc(0 * 100% / 3) 100%,
                        calc(1 * 100% / 3) 0,
                        calc(2 * 100% / 3) 0,
                        calc(3 * 100% / 3) 50%;
                }
                50% {
                    background-position:
                        calc(0 * 100% / 3) 100%,
                        calc(1 * 100% / 3) 100%,
                        calc(2 * 100% / 3) 0,
                        calc(3 * 100% / 3) 0;
                }
                62.5% {
                    background-position:
                        calc(0 * 100% / 3) 50%,
                        calc(1 * 100% / 3) 100%,
                        calc(2 * 100% / 3) 100%,
                        calc(3 * 100% / 3) 0;
                }
                75% {
                    background-position:
                        calc(0 * 100% / 3) 50%,
                        calc(1 * 100% / 3) 50%,
                        calc(2 * 100% / 3) 100%,
                        calc(3 * 100% / 3) 100%;
                }
                87.5% {
                    background-position:
                        calc(0 * 100% / 3) 50%,
                        calc(1 * 100% / 3) 50%,
                        calc(2 * 100% / 3) 50%,
                        calc(3 * 100% / 3) 100%;
                }
                95%,
                100% {
                    background-position:
                        calc(0 * 100% / 3) 50%,
                        calc(1 * 100% / 3) 50%,
                        calc(2 * 100% / 3) 50%,
                        calc(3 * 100% / 3) 50%;
                }
            }
        `;
    }
    /** @override */ render() {
        return html ` ${this._getLoaderHtml()} `;
    }
    _getLoaderHtml() {
        const titleHtml = html `${this.title
            ? html `<div>
                  <h3>${this.title}</h3>
              </div>`
            : html ``}`;
        switch (this.type) {
            case LoadingType.LINE:
                return html ` <div class="progress-container">
                        <paper-progress indeterminate></paper-progress>
                    </div>
                    ${titleHtml}`;
            case LoadingType.SUCCESS:
                return html `<div class="checkmark-wrapper">
                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
                        <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                    ${this.title ? html `<h3>${this.title}</h3>` : ''}
                </div>`;
            case LoadingType.ERROR:
                return html `<div class="checkmark-wrapper">
                    <svg class="exclamationmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle class="exclamationmark-circle" cx="26" cy="26" r="25"></circle>
                        <line class="exclamationmark-line" y2="35.77" x2="20.23" y1="21.23" x1="31.77"></line>
                        <line class="exclamationmark-line" x1="20.23" y1="21.23" y2="35.77" x2="31.77"></line>
                    </svg>

                    ${this.title ? html `<h3>${this.title}</h3>` : ''}
                </div>`;
            case LoadingType.DOT:
                return html `<div class="dot-loader"></div>
                    ${titleHtml}`;
            default:
                return html `
                    <div class="loader"></div>
                    ${titleHtml}
                `;
        }
    }
};
CerumLoading._deps = [];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumLoading.prototype, "title", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumLoading.prototype, "type", void 0);
CerumLoading = __decorate([
    customElement(CerumLoading.is)
], CerumLoading);
export { CerumLoading };
