var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { css, html, LitElement } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { BoostButton } from './common/BoostButton';
import { BoostStatusContainer } from './common/BoostStatusContainer';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { BoostMetoderTilgjengeligEtterInnloggAPI } from 'boost-api/boost/signup/boost_metoder_tilgjengelig_etter_innlogg/BoostMetoderTilgjengeligEtterInnloggAPI';
import { BoostDatePicker } from './common/BoostDatePicker';
import { Checkbox } from '@vaadin/checkbox';
import { CheckboxGroup } from '@vaadin/checkbox-group';
import { EmailField } from '@vaadin/email-field';
import { NumberField } from '@vaadin/number-field';
import { RadioGroup } from '@vaadin/radio-group';
import { RadioButton } from '@vaadin/radio-group/vaadin-radio-button';
import { TextField } from '@vaadin/text-field';
import { CerumFormBuilder } from 'cerumx-shared/CerumFormBuilder';
import moment from 'moment';
let BoostPortalPersonalInformation = class BoostPortalPersonalInformation extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-personal-information';
    }
    static get styles() {
        return css `
            .flex {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
            }

            .flex div {
                flex: 1;
                flex-direction: row;
            }

            .flex div:first-child {
                margin-right: 10px;
            }

            .lookup {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                flex-direction: row;
                width: 100%;
            }

            .lookup-margin {
                margin-right: 20px;
                flex: 1;
            }

            vaadin-text-field,
            vaadin-number-field,
            vaadin-email-field {
                width: 100%;
                padding-bottom: 15px;
            }

            .full-width {
                width: 100%;
            }

            h4 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 10px;
                margin-top: 0;
            }

            a:link,
            a:visited,
            a:active {
                text-decoration: underline;
                color: var(--portalLink, #2a9ba3);
            }

            a:hover {
                text-decoration: none;
            }

            .summary-textbox {
                display: flex;
                margin-top: 20px;
                padding: 10px 5px 5px 10px;
                background-color: #dadfe2;
                border-radius: 6px;
                color: #1e2d3f;
            }

            .not-validated-fields {
                display: flex;
                color: red;
                flex-direction: column;
                gap: 6px;
                flex-flow: wrap;
            }

            #sponsor-text {
                width: 70%;
                align-self: center;
                margin-right: 20px;
                font-size: 16px;
                color: #ff4040;
            }

            #i-sponsor-text {
                font-weight: bold;
                margin: 0px;
                font-size: 18px;
            }

            vaadin-text-field[id='municipality'] > input:disabled {
                --lumo-disabled-text-color: #656565;
            }

            cerum-loading {
                --loading-container-width: 100%;
            }

            @media only screen and (max-width: 600px) {
                .flex {
                    flex-direction: column;
                }

                .flex div:first-child {
                    margin-right: 0px;
                }
                .lookup {
                    width: 100%;
                    flex-direction: column;
                }

                .lookup-margin {
                    margin-right: 0px;
                    flex: 0;
                }
                .full-width {
                    width: 100%;
                    min-width: 100%;
                }
                vaadin-text-field,
                vaadin-number-field,
                vaadin-email-field,
                vaadin-checkbox {
                    width: 100%;
                    padding-bottom: 10px;
                }

                .summary-textbox {
                    flex-direction: column;
                }

                #sponsor-text {
                    width: 100%;
                    margin: 0;
                }

                #i-sponsor-text {
                    font-weight: bold;
                    margin: 0px;
                    font-size: 16px;
                }
            }
        `;
    }
    constructor() {
        super();
        this.memberExists = false;
        this.memberIsUnderaged = false;
        this.hideApproval = false;
        this.departments = [];
        this.isUnknownAreaCode = false;
        this.meetsMinimumAge = true;
        this.meetsShortTermMinimumAge = true;
        this.memberLoggedIn = false;
        this.hideSummaryBox = false;
        this.formBuilder = new CerumFormBuilder(this);
        this.updateComplete.then(() => {
            window.addEventListener('validateFields', this.validateFields.bind(this));
        });
    }
    /** @override */ render() {
        var _a, _b, _c;
        return html `
            ${this.session
            ? html ``
            : html ` <div class="lookup" style="justify-content:end">
                      ${this.isSponsor
                ? html `
                                ${this.sponsoredMember
                    ? html `<span style="width:50%; margin-top:15px;margin-right:80px">
                                          Her skal du som betaler for ${this.sponsoredMember.firstname}
                                          ${this.sponsoredMember.lastname} registrere deg.
                                      </span>`
                    : html `<span id="sponsor-text">
                                          <i id="i-sponsor-text">OBS!</i><br />
                                          I feltene under er det personalia tilhørende den som skal bruke
                                          treningssenteret/trene som skal registreres
                                      </span>`}
                            `
                : html ``}
                      <div>
                          <div style="display: flex; width:60%;">
                              <vaadin-text-field
                                  prevent-invalid-input
                                  pattern="^[0-9]{8,}$"
                                  allowed-char-pattern="[0-9]"
                                  label="Telefonnummer"
                                  value="${(this.loggedInProfile && this.loggedInProfile.mobil) || ''}"
                                  @value-changed="${(event) => this._lookupNumber(event)}"
                                  @keydown="${(event) => this._onPhoneKeydown(event)}"
                              ></vaadin-text-field>

                              <boost-button
                                  id="lookUpButton"
                                  style="padding-top:37px; font-size:86%;"
                                  type="header-button"
                                  name="Søk"
                                  ?disabled="${(this.memberInformation &&
                !this.memberInformation.phone_number) ||
                false}"
                                  @click="${this._personalInfoLookup}"
                              ></boost-button>
                          </div>
                          ${this.memberExists
                ? html `
                                    <boost-status-container type="info">
                                        Velkommen tilbake. Klikk
                                        <a href="${router.baseUrl}${this.centerShort}/login">her</a>
                                        for å reaktivere.
                                    </boost-status-container>
                                `
                : html ``}
                      </div>
                  </div>`}
            <div class="flex">
                <div>
                    <vaadin-text-field
                        ${this.formBuilder.setValidation('firstname')}
                        placeholder="Fornavn"
                        value="${this._getUserFirstName() || ''}"
                        @value-changed="${(event) => this._setFirstName(event)}"
                        required
                        ?disabled="${this.session !== undefined || false}"
                    ></vaadin-text-field>
                </div>

                <div>
                    <vaadin-text-field
                        ${this.formBuilder.setValidation('surname')}
                        placeholder="Etternavn"
                        value="${this._getUserLastName() || ''}"
                        @value-changed="${(event) => this._setLastName(event)}"
                        required
                        ?disabled="${this.session !== undefined || false}"
                    ></vaadin-text-field>
                </div>
            </div>

            <div>
                <vaadin-text-field
                    ${this.formBuilder.setValidation('address')}
                    placeholder="Adresse"
                    value="${this._getUserAddress() || ''}"
                    @value-changed="${(event) => this._setPostalAddress(event)}"
                    required
                ></vaadin-text-field>
            </div>

            <div class="flex">
                <div>
                    <vaadin-text-field
                        ${this.formBuilder.setValidation('postal')}
                        prevent-invalid-input
                        allowed-char-pattern="[0-9]"
                        maxlength="4"
                        placeholder="Postnummer"
                        value="${this._getUserPostalCode() || ''}"
                        @value-changed="${(event) => this._setPostalCode(event)}"
                        required
                    ></vaadin-text-field>
                </div>

                <div>
                    <vaadin-text-field
                        ${this.formBuilder.setValidation('municipality')}
                        placeholder="Poststed"
                        value="${this._getUserMunicipality()}"
                        @value-changed="${(event) => this._setMunicipality(event)}"
                        disabled
                        required
                    ></vaadin-text-field>
                </div>
            </div>

            <div class="flex">
                <div>
                    <vaadin-text-field
                        ${this.formBuilder.setValidation('telephone')}
                        prevent-invalid-input
                        pattern="[0-9]{8}"
                        allowed-char-pattern="[0-9]"
                        helper-text="Foreign phone number? Format: 0046123456789"
                        placeholder="Telefonnummer"
                        value="${this._getUserPhoneNumber() || ''}"
                        @value-changed="${(event) => this._setPhoneNumber(event)}"
                        required
                        ?disabled="${this.session !== undefined || false}"
                    ></vaadin-text-field>
                </div>

                <div>
                    <vaadin-email-field
                        ${this.formBuilder.setValidation('email')}
                        placeholder="E-post"
                        value="${this._getUserEmail() || ''}"
                        @value-changed="${(event) => this._setMail(event)}"
                        required
                    ></vaadin-email-field>
                </div>
            </div>

            <div class="flex">
                <boost-date-picker
                    ${this.formBuilder.setValidation('birthday')}
                    .date="${this._getLoggedInProfileDate()}"
                    @date-changed="${(event) => this._setMemberBirthday(event)}"
                    ?disabled="${this.session ? true : false}"
                ></boost-date-picker>

                <vaadin-radio-group
                    ${this.formBuilder.setValidation('gender')}
                    label="Kjønn"
                    value="${(_a = this._getUserGender()) !== null && _a !== void 0 ? _a : ''}"
                    @value-changed="${(event) => this._setGender(event)}"
                    required
                >
                    <vaadin-radio-button value="mann" label="Mann"></vaadin-radio-button>

                    <vaadin-radio-button value="kvinne" label="Kvinne"></vaadin-radio-button>
                </vaadin-radio-group>
            </div>
            ${this.hideApproval
            ? html ``
            : html `<div class="flex" style="margin-top: 10px;"></div> 

                     ${this.memberLoggedIn
                ? html ``
                : html `<div class="flex" style="margin-top: 10px; margin-bottom:10px;">
                                   <vaadin-checkbox-group label="Avtalevilkår" required>
                                       <vaadin-checkbox
                                           ${this.formBuilder.setValidation('approve_tos')}
                                           @checked-changed="${(event) => this._setTermsOfApproval(event.detail.value)}"
                                           value="1"
                                           style="width:auto"
                                       ></vaadin-checkbox>
                                       <div style="display:flex; gap:4px ">
                                           <span>Jeg har lest og godtatt</span>
                                           <a href="${this._getTermsOfServiceUrl()}" target="_blank">
                                               avtalevilkårene
                                           </a>
                                       </div>
                                   </vaadin-checkbox-group>
                               </div>`} 
                    ${!this.memberLoggedIn && !this.hideSummaryBox
                ? html ` <span style="color:#63666a;">Du har valgt: </span>
                                  <div class="summary-container">
                                      <div class="summary-textbox">
                                          <span style="padding-left:4px">
                                              ${(_b = this.getMembershipById()) === null || _b === void 0 ? void 0 : _b.title},
                                          </span>

                                          <span style="padding-left:4px">
                                              ${(_c = this.getDepartmentById()) === null || _c === void 0 ? void 0 : _c.navn}
                                          </span>
                                      </div>
                                  </div>`
                : html ``}   
            
                          ${this.formBuilder.invalidFields.length
                ? this._renderNotValidatedFields(this.formBuilder.invalidFields)
                : ''}
                      </div>
                  </div> 
               `}
        `;
    }
    _onPhoneKeydown(event) {
        if (event.code == 'Enter') {
            this._personalInfoLookup();
        }
    }
    _setTermsOfApproval(value) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.approve_tos = value;
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
    }
    _setGender(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.gender = event.detail.value;
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
    }
    _setMail(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.email = event.detail.value.toLowerCase();
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
    }
    _setPhoneNumber(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.phone_number = event.detail.value;
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
        this.dispatchEvent(new CustomEvent('memberPhoneNumber-changed', {
            detail: {
                memberPhoneNumber: this.memberInformation.phone_number,
            },
            bubbles: true,
            composed: true,
        }));
    }
    _setMunicipality(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.municipality = event.detail.value;
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
    }
    _setPostalCode(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.postal_code = event.detail.value;
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
        if (this.postalCodeLookupTimeout) {
            clearTimeout(this.postalCodeLookupTimeout);
        }
        this.postalCodeLookupTimeout = window.setTimeout(() => {
            this._lookupMunicipality();
        }, 400);
    }
    _setPostalAddress(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.address = this._capitalizeWordBoundary(event.detail.value);
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
    }
    _setLastName(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.lastname = this._capitalizeWordBoundary(event.detail.value);
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
    }
    _setFirstName(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.firstname = this._capitalizeWordBoundary(event.detail.value);
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
    }
    _lookupNumber(event) {
        if (!this.memberInformation) {
            return;
        }
        this.memberInformation.phone_number = event.detail.value;
        this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
    }
    _setMemberBirthday(event) {
        if (!this.memberInformation) {
            return;
        }
        const datePickerDate = event.detail.date;
        if (datePickerDate.date && datePickerDate.month && datePickerDate.year) {
            const selectedDate = moment(datePickerDate.date + '.' + datePickerDate.month + '.' + datePickerDate.year, 'DD.MM.YYYY');
            this.memberInformation.date_of_birth = selectedDate.format('DD.MM.YYYY');
            this._memberAgeCheck(selectedDate);
            this._memberShortTermAgeCheck(selectedDate);
            this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
        }
    }
    _renderNotValidatedFields(invalidFields) {
        return html `
            <div style="padding-top: 20px;">
                <span>Fyll inn de manglende feltene</span>
                <div class="not-validated-fields">
                    ${invalidFields.map((field) => {
            var _a;
            return html `<span>* ${(_a = field.invalidTitle) !== null && _a !== void 0 ? _a : field.id}</span>`;
        })}
                </div>
            </div>
        `;
    }
    getDepartmentById() {
        return this.departments.find((department) => department.id === this.selectedDepartmentId);
    }
    getMembershipById() {
        var _a;
        return (_a = this.memberships) === null || _a === void 0 ? void 0 : _a.find((membership) => membership.id === this.selectedMembershipId);
    }
    _sessionObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.session) {
                return;
            }
            const profileResult = yield BoostMetoderTilgjengeligEtterInnloggAPI.getExerciseProfile({
                session: this.session,
            });
            if (isSuccessHTTP(profileResult)) {
                this.dispatch(BoostPortalStore.Actions.SetLoggedInProfile(profileResult));
                this.validateFields();
            }
            else if (isErrorHTTP(profileResult)) {
                SnackBar.pushError(profileResult.error);
            }
        });
    }
    _getTermsOfServiceUrl() {
        if (!this.center || !this.selectedDepartmentId) {
            return '#';
        }
        const termsOfService = this.center.link_to_tos.find((terms) => terms.id === this.selectedDepartmentId);
        if (!termsOfService || !termsOfService.url) {
            return '#';
        }
        return termsOfService.url;
    }
    _getUserFirstName() {
        if (this.loggedInProfile && this.loggedInProfile.fornavn) {
            return this.loggedInProfile.fornavn;
        }
        if (this.memberInformation && this.memberInformation.firstname) {
            return this.memberInformation.firstname;
        }
    }
    _getUserLastName() {
        if (this.loggedInProfile && this.loggedInProfile.etternavn) {
            return this.loggedInProfile.etternavn;
        }
        if (this.memberInformation && this.memberInformation.lastname) {
            return this.memberInformation.lastname;
        }
    }
    _getUserGender() {
        if (this.loggedInProfile && this.loggedInProfile.kjonn) {
            return this.loggedInProfile.kjonn === 1 ? 'mann' : 'kvinne';
        }
        if (this.memberInformation && this.memberInformation.gender) {
            return this.memberInformation.gender;
        }
    }
    _getUserAddress() {
        if (this.loggedInProfile && this.loggedInProfile.adresse) {
            return this.loggedInProfile.adresse;
        }
        if (this.memberInformation && this.memberInformation.address) {
            return this.memberInformation.address;
        }
    }
    _getUserPostalCode() {
        if (this.loggedInProfile && this.loggedInProfile.postnr) {
            return this.loggedInProfile.postnr;
        }
        if (this.memberInformation && this.memberInformation.postal_code) {
            return this.memberInformation.postal_code;
        }
    }
    _getUserMunicipality() {
        if (this.isUnknownAreaCode) {
            return 'Ukjent postnummer';
        }
        if (this.loggedInProfile && this.loggedInProfile.poststed) {
            return this.loggedInProfile.poststed;
        }
        if (this.memberInformation && this.memberInformation.municipality) {
            return this.memberInformation.municipality;
        }
        return '';
    }
    _getUserPhoneNumber() {
        if (this.loggedInProfile && this.loggedInProfile.mobil) {
            return this.loggedInProfile.mobil;
        }
        if (this.memberInformation && this.memberInformation.phone_number) {
            return this.memberInformation.phone_number;
        }
    }
    _getUserEmail() {
        if (this.loggedInProfile && this.loggedInProfile.epost) {
            return this.loggedInProfile.epost;
        }
        if (this.memberInformation && this.memberInformation.email) {
            return this.memberInformation.email;
        }
    }
    _capitalizeWordBoundary(name) {
        return name.replace(/(^|\s)\S/g, (replacedName) => replacedName.toUpperCase());
    }
    _getInitialBirthDate() {
        if (this.memberInformation && this.memberInformation.date_of_birth) {
            const dateFields = this.memberInformation.date_of_birth.split('.');
            const date = dateFields[0];
            const month = dateFields[1];
            const year = dateFields[2];
            return year.toString() + '-' + month.toString() + '-' + date;
        }
        if (this.loggedInProfile && this.loggedInProfile.fodttimestamp) {
            const dateOfBirth = new Date(this.loggedInProfile.fodttimestamp * 1000);
            if (this.memberInformation) {
                this.memberInformation.date_of_birth =
                    dateOfBirth.getFullYear() +
                        '.' +
                        (dateOfBirth.getMonth() + 1) +
                        '.' +
                        dateOfBirth.getDate();
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
            }
            return (dateOfBirth.getFullYear() +
                '-' +
                ('0' + (dateOfBirth.getMonth() + 1)).toString().slice(-2) +
                '-' +
                ('0' + dateOfBirth.getDate()).toString().slice(-2));
        }
        return '';
    }
    _getTodaysDate() {
        const dateObj = new Date();
        const date = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        return year + '-' + month + '-' + date;
    }
    _lookupMunicipality() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.memberInformation || !this.memberInformation.postal_code) {
                return;
            }
            const municipalityResult = yield MembersignupAPI.getV1BoostsignupLookupMunicipalityUsingAreacode(this.memberInformation.postal_code);
            if (isSuccessHTTP(municipalityResult)) {
                if (municipalityResult.municipality) {
                    this.memberInformation.municipality = municipalityResult.municipality;
                    this.isUnknownAreaCode = false;
                    this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                }
            }
            else if (isErrorHTTP(municipalityResult)) {
                if (municipalityResult.error.message === 'Unknown areacode') {
                    this.isUnknownAreaCode = true;
                }
                this.memberInformation.municipality = '';
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
            }
        });
    }
    _memberAgeCheck(birthDate) {
        if (!this.center) {
            return false;
        }
        if (!this.center.short_term_minimum_age) {
            this.center.short_term_minimum_age = 0;
        }
        const userAge = moment().diff(birthDate, 'years');
        if (!this.center.minimum_age) {
            this.center.minimum_age = 0;
        }
        this.meetsMinimumAge = userAge >= this.center.minimum_age;
        this.memberIsUnderaged = userAge < 18;
    }
    _meetsMinimumAgeObserver() {
        this.dispatchEvent(new CustomEvent('meetsMinimumAge', {
            detail: {
                value: this.meetsMinimumAge,
            },
            bubbles: true,
            composed: true,
        }));
    }
    _memberShortTermAgeCheck(birthDate) {
        if (!this.center) {
            return false;
        }
        if (!this.center.short_term_minimum_age) {
            return;
        }
        const userAge = moment().diff(birthDate, 'years');
        this.meetsShortTermMinimumAge = userAge >= this.center.short_term_minimum_age;
    }
    _meetsShortTermMinimumAgeObserver() {
        this.dispatchEvent(new CustomEvent('meetsShortTermMinimumAge', {
            detail: {
                value: this.meetsShortTermMinimumAge,
            },
            bubbles: true,
            composed: true,
        }));
    }
    _memberIsUnderagedObserver() {
        this.dispatchEvent(new CustomEvent('memberIsUnderaged-changed', {
            detail: {
                memberIsUnderaged: this.memberIsUnderaged,
            },
            bubbles: true,
            composed: true,
        }));
    }
    _personalInfoLookup() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            if ((_a = this.lookUpButton) === null || _a === void 0 ? void 0 : _a.disabled) {
                return;
            }
            if (!this.memberInformation || !this.memberInformation.phone_number) {
                return;
            }
            this.memberExists = false;
            (_b = this.lookUpButton) === null || _b === void 0 ? void 0 : _b.setLoading(true);
            const lookupResult = yield MembersignupAPI.getV1BoostsignupLookupPhoneUsingPhonenumber(this.memberInformation.phone_number);
            if (isSuccessHTTP(lookupResult) && lookupResult.length) {
                if (lookupResult[0].exists) {
                    this.memberExists = true;
                }
                else {
                    this.memberInformation.firstname = lookupResult[0].firstname;
                    this.memberInformation.lastname = lookupResult[0].lastname;
                    this.memberInformation.address = lookupResult[0].address;
                    this.memberInformation.postal_code = lookupResult[0].postal_code;
                    this.memberInformation.municipality = lookupResult[0].municipality;
                    this.requestUpdate('memberInformation');
                }
            }
            else if (isErrorHTTP(lookupResult)) {
                SnackBar.pushError(lookupResult.error);
            }
            (_c = this.lookUpButton) === null || _c === void 0 ? void 0 : _c.setLoading(false);
        });
    }
    validateFields() {
        this.formBuilder.schema = {
            firstname: { expect: 'filled', errorMessage: 'Fornavn må fylles ut', invalidTitle: 'Fornavn' },
            surname: { expect: 'filled', errorMessage: 'Etternavn må fylles ut', invalidTitle: 'Etternavn' },
            address: { expect: 'filled', errorMessage: 'Adresse må fylles ut', invalidTitle: 'Adresse' },
            postal: { expect: 'filled', errorMessage: 'Postnummer må fylles ut', invalidTitle: 'Postnummer' },
            municipality: { expect: 'filled' },
            telephone: {
                expect: 'filled',
                errorMessage: 'Telefonnummer må fylles ut',
                invalidTitle: 'Telefon',
            },
            email: { expect: 'filled', errorMessage: 'E-post må fylles ut', invalidTitle: 'E-post' },
            gender: { expect: true, errorMessage: 'Velg kjønn', invalidTitle: 'Kjønn' },
            birthday: {
                customValidation: () => {
                    var _a, _b;
                    return [
                        Boolean((_a = this.memberInformation) === null || _a === void 0 ? void 0 : _a.date_of_birth),
                        (_b = this.memberInformation) === null || _b === void 0 ? void 0 : _b.date_of_birth,
                    ];
                },
                invalidTitle: 'Fødselsdato',
            },
            approve_tos: {
                expect: true,
                errorMessage: 'Avtalevilkårene må godtas',
                invalidTitle: 'Avtalevilkår',
            },
        };
        return this.formBuilder.validate();
    }
    _getLoggedInProfileDate() {
        var _a;
        if (!this.loggedInProfile) {
            if (this.memberInformation) {
                const dateOfBirthArray = this.memberInformation.date_of_birth.split('.');
                if (!dateOfBirthArray.length) {
                    return {};
                }
                return {
                    date: parseInt(dateOfBirthArray[0]) || undefined,
                    month: parseInt(dateOfBirthArray[1]) || undefined,
                    year: parseInt(dateOfBirthArray[2]) || undefined,
                };
            }
            return {};
        }
        const dateOfBirthArray = (_a = this.loggedInProfile.birth) === null || _a === void 0 ? void 0 : _a.split('-');
        if (!dateOfBirthArray) {
            return {};
        }
        return {
            date: parseInt(dateOfBirthArray[2]),
            month: parseInt(dateOfBirthArray[1]),
            year: parseInt(dateOfBirthArray[0]),
        };
    }
    _municipalityObserver() {
        var _a, _b;
        const municipality = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#municipality');
        if (!this.isUnknownAreaCode && municipality) {
            municipality.invalid = false;
        }
        else if (municipality && ((_b = this.memberInformation) === null || _b === void 0 ? void 0 : _b.postal_code.length) === 4) {
            municipality.invalid = true;
        }
    }
    /** @override */
    updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'memberIsUnderaged':
                    this._memberIsUnderagedObserver();
                    break;
                case 'meetsMinimumAge':
                    this._meetsMinimumAgeObserver();
                    break;
                case 'meetsShortTermMinimumAge':
                    this._meetsShortTermMinimumAgeObserver();
                    break;
                case 'session':
                    this._sessionObserver();
                    break;
                case 'isUnknownAreaCode':
                case 'memberInformation':
                    this._municipalityObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
BoostPortalPersonalInformation._deps = [
    BoostButton,
    BoostStatusContainer,
    BoostDatePicker,
    TextField,
    NumberField,
    EmailField,
    RadioGroup,
    RadioButton,
    CheckboxGroup,
    Checkbox,
];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalPersonalInformation.prototype, "centerShort", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalPersonalInformation.prototype, "memberExists", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalPersonalInformation.prototype, "center", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalPersonalInformation.prototype, "memberInformation", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalPersonalInformation.prototype, "memberIsUnderaged", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalPersonalInformation.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedMembershipId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalPersonalInformation.prototype, "selectedMembershipId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalPersonalInformation.prototype, "session", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('loggedInProfile')),
    state(),
    __metadata("design:type", Object)
], BoostPortalPersonalInformation.prototype, "loggedInProfile", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalPersonalInformation.prototype, "hideApproval", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalPersonalInformation.prototype, "isSponsor", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], BoostPortalPersonalInformation.prototype, "sponsoredMember", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('departments')),
    state(),
    __metadata("design:type", Array)
], BoostPortalPersonalInformation.prototype, "departments", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberships')),
    state(),
    __metadata("design:type", Array)
], BoostPortalPersonalInformation.prototype, "memberships", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalPersonalInformation.prototype, "isUnknownAreaCode", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostPortalPersonalInformation.prototype, "postalCodeLookupTimeout", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalPersonalInformation.prototype, "meetsMinimumAge", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalPersonalInformation.prototype, "meetsShortTermMinimumAge", void 0);
__decorate([
    query('#lookUpButton'),
    __metadata("design:type", BoostButton)
], BoostPortalPersonalInformation.prototype, "lookUpButton", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalPersonalInformation.prototype, "memberLoggedIn", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalPersonalInformation.prototype, "hideSummaryBox", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalPersonalInformation.prototype, "formBuilder", void 0);
BoostPortalPersonalInformation = __decorate([
    customElement(BoostPortalPersonalInformation.is),
    __metadata("design:paramtypes", [])
], BoostPortalPersonalInformation);
export { BoostPortalPersonalInformation };
