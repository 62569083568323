var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { BoostPortalMemberPrivatelySponsoredProgress } from '../components/BoostPortalMemberPrivatelySponsoredProgress';
import { BoostButton } from '../components/common/BoostButton';
import { BoostPortalChooseDepartment } from '../components/BoostPortalChooseDepartment';
import { BoostPortalChooseMembership } from '../components/BoostPortalChooseMembership';
import { BoostPortalLookupSponsor } from '../components/BoostPortalLookupSponsor';
import { BoostPortalPersonalInformation } from '../components/BoostPortalPersonalInformation';
import { BoostPortalValidateSponsorCode } from '../components/BoostPortalValidateSponsorCode';
import { BoostStepIndicator } from '../components/common/BoostStepIndicator';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
import { TrackingEvent } from '../tracking/TrackingEvent';
let BoostPortalRegisterMemberPrivatelySponsoredView = class BoostPortalRegisterMemberPrivatelySponsoredView extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-register-member-privately-sponsored-view';
    }
    static get styles() {
        return css `
            :host {
                width: 90%;
            }
        `;
    }
    constructor() {
        super();
        this.currentStep = 1;
        this.isValidSponsorCode = false;
        this.memberExists = false;
        this.meetsMinimumAge = true;
        this.updateComplete.then(() => __awaiter(this, void 0, void 0, function* () {
            const urlParameters = new URLSearchParams(location.search);
            const memberCreated = urlParameters.get('success');
            if (memberCreated) {
                this.memberCreated = memberCreated;
            }
        }));
    }
    /** @override */ render() {
        return html `
            <boost-portal-header .viewLoginButton="${false}"></boost-portal-header>
            ${this.currentStep === 1
            ? html `<p>Avtal dette med den som skal betale <i>før</i> du starter registreringen!</p> `
            : html ``}
            ${this.memberCreated
            ? html `
                      ${this.memberCreated === 'true'
                ? html `
                                <boost-status type="success">
                                    Medlem er opprettet. Du skal ha mottatt en e-post med utfyllende
                                    informasjon og en SMS med passord.
                                </boost-status>
                            `
                : html `
                                <boost-status type="danger">
                                    Medlem ble ikke opprettet. Ta kontakt med senteret for mer informasjon.
                                </boost-status>
                            `}
                  `
            : html `
                      <boost-step-indicator
                          currentStep="${this.currentStep}"
                          .steps="${this._getSteps(this.currentStep)}"
                          @currentStep-changed="${(event) => {
                this.currentStep = event.detail.currentStep;
            }}"
                      ></boost-step-indicator>

                      ${this._showViewForCurrentStep(this.currentStep)}
                  `}
        `;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'currentStep':
                    this._currentStepObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _createMember(event) {
        return __awaiter(this, void 0, void 0, function* () {
            window.dispatchEvent(new CustomEvent('validateFields', { bubbles: true, composed: true }));
            const buttonElement = event.srcElement;
            if (buttonElement.disabled) {
                return;
            }
            if (!this.memberInformation ||
                !this._isValidMemberInformation() ||
                !this.selectedDepartmentId ||
                !this.selectedMembershipId) {
                return;
            }
            this.memberInformation.is_privately_sponsored = true;
            this.memberInformation.departmentId = this.selectedDepartmentId;
            this.memberInformation.membershipId = this.selectedMembershipId;
            this.memberInformation.netsCallbackUrl =
                window.location.origin +
                    router.baseUrl +
                    this.centerShort +
                    '/avtalegiro?departmentId=' +
                    this.selectedDepartmentId;
            this.requestUpdate('memberInformation');
            buttonElement.setLoading(true);
            const createdMemberResult = yield MembersignupAPI.postV1BoostsignupMember(this.memberInformation);
            if (isSuccessHTTP(createdMemberResult)) {
                TrackingFactorySingleton.asyncTracker((tracker) => tracker.completedEvent(TrackingEvent.PersonalInformationFilled));
                this.memberInformation = createdMemberResult;
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                this.currentStep += 1;
            }
            else if (isErrorHTTP(createdMemberResult)) {
                if (createdMemberResult.error.http_status === 409) {
                    this.memberExists = true;
                }
                else {
                    SnackBar.pushError(createdMemberResult.error);
                }
            }
            buttonElement.setLoading(false);
        });
    }
    _currentStepObserver() {
        if (this.currentStep > this._determineCurrentStep()) {
            this.currentStep = this._determineCurrentStep();
        }
    }
    _determineCurrentStep() {
        if (!this.selectedDepartmentId || !this.selectedMembershipId) {
            return 1;
        }
        else if (!this._isValidMemberInformation()) {
            return 2;
        }
        return 3;
    }
    _getSteps(currentStep) {
        const steps = [
            {
                title: 'Avtale',
                isActive: currentStep === 1,
                subtitle: '',
            },
            {
                title: 'Personalia',
                isActive: currentStep === 2,
                subtitle: '',
            },
            {
                title: 'Betaler',
                isActive: currentStep === 3,
                subtitle: '',
            },
        ];
        return steps;
    }
    _isValidMemberInformation() {
        if (!this.memberInformation) {
            return false;
        }
        if (!this.memberInformation.firstname ||
            !this.memberInformation.lastname ||
            !this.memberInformation.address ||
            !this.memberInformation.postal_code ||
            !this.memberInformation.municipality ||
            !this.memberInformation.phone_number ||
            !this.memberInformation.email ||
            !this.memberInformation.date_of_birth ||
            !this.memberInformation.gender ||
            !this.memberInformation.approve_tos) {
            return false;
        }
        return true;
    }
    _showViewForCurrentStep(currentStep) {
        var _a;
        switch (currentStep) {
            case 1:
                return html `
                    <boost-portal-choose-department></boost-portal-choose-department>
                    ${this.selectedDepartmentId
                    ? html `<boost-portal-choose-membership></boost-portal-choose-membership>`
                    : html `<div style="padding-bottom:16px;"></div>`}
                    <div>
                        <boost-button
                            type="submit"
                            name="Gå videre"
                            @click="${() => {
                    if (!this.selectedDepartmentId || !this.selectedMembershipId) {
                        return;
                    }
                    this.currentStep += 1;
                }}"
                            ?disabled="${!this.selectedDepartmentId || !this.selectedMembershipId}"
                            width-full
                        ></boost-button>
                    </div>
                `;
            case 2:
                return html `<boost-portal-member-privately-sponsored-progress></boost-portal-member-privately-sponsored-progress>

                    <boost-portal-personal-information
                        isSponsor
                        @memberPhoneNumber-changed="${() => (this.memberExists = false)}"
                        @meetsMinimumAge="${(event) => (this.meetsMinimumAge = event.detail.value)}"
                    >
                    </boost-portal-personal-information>

                    ${this.memberExists
                    ? html `
                              <boost-status-container type="info">
                                  ${this.isLoggedIn()
                        ? 'Du har allerede et aktivt medlemskap og kan ikke melde deg inn på nytt'
                        : html ` Du har vært medlem tidligere.
                                            <a href="${router.baseUrl}${this.centerShort}/login">Trykk her</a>
                                            for å inngå nytt medlemskap.`}
                              </boost-status-container>
                          `
                    : html `
                              ${!this.meetsMinimumAge
                        ? html `<boost-status-container type="info">
                                        For å registrere deg hos oss må du være minst
                                        ${(_a = this.center) === null || _a === void 0 ? void 0 : _a.minimum_age} år gammel. Ta kontakt med senteret hvis du
                                        lurer på noe.
                                    </boost-status-container>`
                        : html `<div>
                                        <boost-button
                                            type="submit"
                                            name="Gå videre"
                                            @click="${this._createMember}"
                                            ?disabled="${!this._isValidMemberInformation()}"
                                            width-full
                                        ></boost-button>
                                    </div>`}
                          `} `;
            case 3:
                return html ` <boost-portal-member-privately-sponsored-progress></boost-portal-member-privately-sponsored-progress>

                    <boost-portal-lookup-sponsor></boost-portal-lookup-sponsor>`;
        }
    }
    _updateMember(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const buttonElement = event.srcElement;
            if (buttonElement.disabled) {
                return;
            }
            if (!this._isValidMemberInformation() && this.isValidSponsorCode) {
                return;
            }
            if (!this.sponsorCode) {
                return;
            }
            if (!this.memberInformation) {
                return;
            }
            buttonElement.setLoading(true);
            const updateMemberResult = yield MembersignupAPI.postV1BoostsignupSponsorUsingSponsorcode(this.sponsorCode, {
                departmentId: this.memberInformation.departmentId,
                memberId: this.memberInformation.memberId,
                contractId: this.memberInformation.contractId,
            });
            if (isSuccessHTTP(updateMemberResult)) {
                TrackingFactorySingleton.asyncTracker((tracker) => tracker.completedEvent(TrackingEvent.SponsorConfirmed));
                window.location.replace(window.location.href + '?success=true');
            }
            else if (isErrorHTTP(updateMemberResult)) {
                SnackBar.pushError(updateMemberResult.error);
            }
            buttonElement.setLoading(false);
        });
    }
    isLoggedIn() {
        var _a;
        return this.session && ((_a = this.loggedInProfile) === null || _a === void 0 ? void 0 : _a.memberid);
    }
};
BoostPortalRegisterMemberPrivatelySponsoredView._deps = [
    BoostButton,
    BoostStepIndicator,
    BoostPortalChooseDepartment,
    BoostPortalChooseMembership,
    BoostPortalPersonalInformation,
    BoostPortalMemberPrivatelySponsoredProgress,
    BoostPortalValidateSponsorCode,
    BoostPortalLookupSponsor,
    BoostPortalHeader,
];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "centerShort", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "center", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "currentStep", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "isValidSponsorCode", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "memberCreated", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "memberInformation", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedMembershipId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "selectedMembershipId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('sponsorCode')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "sponsorCode", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "memberExists", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "session", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('loggedInProfile')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "loggedInProfile", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberPrivatelySponsoredView.prototype, "meetsMinimumAge", void 0);
BoostPortalRegisterMemberPrivatelySponsoredView = __decorate([
    customElement(BoostPortalRegisterMemberPrivatelySponsoredView.is),
    __metadata("design:paramtypes", [])
], BoostPortalRegisterMemberPrivatelySponsoredView);
export { BoostPortalRegisterMemberPrivatelySponsoredView };
