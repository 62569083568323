/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { DeleteTaskResult } from '../../../schemas/DeleteTaskResult';
import { IdentityInvite } from '../../../schemas/IdentityInvite';
import { PatchTaskResult } from '../../../schemas/PatchTaskResult';
import { SafiAgreement } from '../../../schemas/SafiAgreement';
import { SafiAttachment } from '../../../schemas/SafiAttachment';
import { SafiConversation } from '../../../schemas/SafiConversation';
import { SafiDocument } from '../../../schemas/SafiDocument';
import { SafiLogbookEntry } from '../../../schemas/SafiLogbookEntry';
import { SafiLogbookOverview } from '../../../schemas/SafiLogbookOverview';
import { SafiLoginResult } from '../../../schemas/SafiLoginResult';
import { SafiMessage } from '../../../schemas/SafiMessage';
import { SafiSelectedAgreement } from '../../../schemas/SafiSelectedAgreement';
import { SafiUnreadConversations } from '../../../schemas/SafiUnreadConversations';
import { TaskHistory } from '../../../schemas/TaskHistory';
import { WorkplanningAutoAssignResponse } from '../../../schemas/WorkplanningAutoAssignResponse';
import { WorkplanningHoursSummary } from '../../../schemas/WorkplanningHoursSummary';
import { WorkplanningTask } from '../../../schemas/WorkplanningTask';
import { WorkplanningTaskSummary } from '../../../schemas/WorkplanningTaskSummary';
export class RenholdAPI extends APIRestClient {
    /**
     * Slett en oppgave
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*deleteTask*
     */
    static deleteV1SafiWorkplanningTasksUsingTaskId(task_id) {
        return this._delete('/v1/safi/workplanning/tasks/{task_id}', { task_id }, null, null, DeleteTaskResult.classOf(DeleteTaskResult, new DeleteTaskResult()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent tilgjengelige avtaler
     * Implementert hovedsakelig i *ewRestResourceV1Agreements*::*read*
     */
    static getV1SafiAgreements() {
        return this._get('/v1/safi/agreements', {}, null, null, SafiAgreement.classOf(SafiAgreement, new SafiAgreement()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent alle samtaler p� en avtale
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsConversations*::*read*
     */
    static getV1SafiAgreementsConversationsUsingAgreementid(agreementID) {
        return this._get('/v1/safi/agreements/{agreementID}/conversations', { agreementID }, null, null, SafiConversation.classOf(SafiConversation, new SafiConversation()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent alle dokumenter p� en avtale
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsDocuments*::*read*
     */
    static getV1SafiAgreementsDocumentsUsingAgreementid(agreementID) {
        return this._get('/v1/safi/agreements/{agreementID}/documents', { agreementID }, null, null, SafiDocument.classOf(SafiDocument, new SafiDocument()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Gir siste og neste innslag i logboken
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsLogbook*::*getLogbookOverview*
     */
    static getV1SafiAgreementsLogbookOverviewUsingAgreementid(agreementID) {
        return this._get('/v1/safi/agreements/{agreementID}/logbook/overview', { agreementID }, null, null, SafiLogbookOverview.classOf(SafiLogbookOverview, new SafiLogbookOverview()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Gir alle logginnslag p� en avtale
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsLogbook*::*getLogbook*
     */
    static getV1SafiAgreementsLogbookUsingAgreementid(agreementID) {
        return this._get('/v1/safi/agreements/{agreementID}/logbook', { agreementID }, null, null, SafiLogbookEntry.classOf(SafiLogbookEntry, new SafiLogbookEntry()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Henter ut avtalen den valgte avtalen
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsSelect*::*read*
     */
    static getV1SafiAgreementsSelected() {
        return this._get('/v1/safi/agreements/selected', {}, null, null, SafiSelectedAgreement.classOf(SafiSelectedAgreement, new SafiSelectedAgreement()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Henter en attachment
     * Implementert hovedsakelig i *ewRestResourceV1SafiAttachments*::*read*
     * bpRestRequestStreamResponse
     */
    static getV1SafiAttachmentsUsingAttachmentid(attachmentID, __QUERY) {
        return this._get('/v1/safi/attachments/{attachmentID}', { attachmentID }, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.cerum.no/cerumx/login'], 'blob');
    }
    /**
     * Hent alle meldinger
     * Implementert hovedsakelig i *ewRestResourceV1SafiConversationMessages*::*read*
     */
    static getV1SafiConversationsMessagesUsingConversationid(conversationID, __QUERY) {
        return this._get('/v1/safi/conversations/{conversationID}/messages', { conversationID }, __QUERY, null, SafiMessage.classOf(SafiMessage, new SafiMessage()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent alle uleste samtaler
     * Implementert hovedsakelig i *ewRestResourceV1SafiMessagesUnread*::*getUnreadConversations*
     */
    static getV1SafiConversationsUnread(__QUERY) {
        return this._get('/v1/safi/conversations/unread', {}, __QUERY, null, SafiUnreadConversations.classOf(SafiUnreadConversations, new SafiUnreadConversations()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent et dokument
     * Implementert hovedsakelig i *ewRestResourceV1SafiDocuments*::*read*
     */
    static getV1SafiDocumentsUsingNodeid(nodeID, __QUERY) {
        return this._get('/v1/safi/documents/{nodeID}', { nodeID }, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.cerum.no/cerumx/login'], 'blob');
    }
    /**
     * Hent antall uleste meldinger
     * Implementert hovedsakelig i *ewRestResourceV1SafiMessagesUnread*::*getUnreadMessageCount*
     */
    static getV1SafiMessagesUnread(__QUERY) {
        return this._get('/v1/safi/messages/unread', {}, __QUERY, null, APIRestClient.Native.number(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * News articles
     * Implementert hovedsakelig i *ewRestResourceV1SafiNews*::*getNewsArticles*
     */
    static getV1SafiNewsArticles(__QUERY) {
        return this._get('/v1/safi/news/articles', {}, __QUERY, null, APIRestClient.Native.number(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Henter timer ut fra forutsetninger
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*getHoursForTimePeriod*
     */
    static getV1SafiWorkplanningHours(__QUERY) {
        return this._get('/v1/safi/workplanning/hours', {}, __QUERY, null, WorkplanningHoursSummary.classOf(WorkplanningHoursSummary, new WorkplanningHoursSummary()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Henter oppgaver ut fra forutsetninger
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*getTasksForTimePeriod*
     */
    static getV1SafiWorkplanningTasks(__QUERY) {
        return this._get('/v1/safi/workplanning/tasks', {}, __QUERY, null, WorkplanningTaskSummary.classOf(WorkplanningTaskSummary, new WorkplanningTaskSummary()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Henter historie for oppgave
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*getHistoryForTask*
     */
    static getV1SafiWorkplanningTasksHistoryUsingTaskId(task_id) {
        return this._get('/v1/safi/workplanning/tasks/{task_id}/history', { task_id }, null, null, TaskHistory.classOf(TaskHistory, new TaskHistory()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Henter oppgaver som ikker tildelt ut fra forutsetninger
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*getUnassignedTasksForTimePeriod*
     */
    static getV1SafiWorkplanningTasksUnassigned(__QUERY) {
        return this._get('/v1/safi/workplanning/tasks/unassigned', {}, __QUERY, null, WorkplanningTask.classOf(WorkplanningTask, new WorkplanningTask()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Henter en oppgave
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*getTaskById*
     */
    static getV1SafiWorkplanningTasksUsingTaskId(task_id) {
        return this._get('/v1/safi/workplanning/tasks/{task_id}', { task_id }, null, null, WorkplanningTask.classOf(WorkplanningTask, new WorkplanningTask()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Endre en oppgave
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*patchTask*
     */
    static patchV1SafiWorkplanningTasksUsingTaskId(task_id, __DATA) {
        return this._patch('/v1/safi/workplanning/tasks/{task_id}', { task_id }, null, JSON.stringify(__DATA), PatchTaskResult.classOf(PatchTaskResult, new PatchTaskResult()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Lag en ny samtale p� en avtale
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsConversations*::*create*
     */
    static postV1SafiAgreementsConversationsUsingAgreementid(agreementID, __DATA) {
        return this._post('/v1/safi/agreements/{agreementID}/conversations', { agreementID }, null, JSON.stringify(__DATA), SafiConversation.classOf(SafiConversation, new SafiConversation()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Inviter person til avtale
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsInvite*::*create*
     */
    static postV1SafiAgreementsInviteUsingAgreementid(agreementID, __DATA) {
        return this._post('/v1/safi/agreements/{agreementID}/invite', { agreementID }, null, JSON.stringify(__DATA), IdentityInvite.classOf(IdentityInvite, new IdentityInvite()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Link en identitet til en avtale
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsLink*::*create*
     */
    static postV1SafiAgreementsLink() {
        return this._post('/v1/safi/agreements/link', {}, null, null, SafiAgreement.classOf(SafiAgreement, new SafiAgreement()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Veksle inn Cerum-ID-basert refresh-token i Cerum-ID-basert access- og refresh-token
     * Implementert hovedsakelig i *ewRestResourceV1SafiLogin*::*create*
     */
    static postV1SafiAuthorize(__QUERY) {
        return this._post('/v1/safi/authorize', {}, __QUERY, null, SafiLoginResult.classOf(SafiLoginResult, new SafiLoginResult()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Lag ny melding
     * Implementert hovedsakelig i *ewRestResourceV1SafiConversationMessages*::*create*
     */
    static postV1SafiConversationsMessagesUsingConversationid(conversationID, __DATA) {
        return this._post('/v1/safi/conversations/{conversationID}/messages', { conversationID }, null, JSON.stringify(__DATA), SafiMessage.classOf(SafiMessage, new SafiMessage()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Veksle inn ekstern ID-token i Cerum-ID-basert access- og refresh-token
     * Implementert hovedsakelig i *ewRestResourceV1SafiLogin*::*create*
     */
    static postV1SafiLogin(__QUERY) {
        return this._post('/v1/safi/login', {}, __QUERY, null, SafiLoginResult.classOf(SafiLoginResult, new SafiLoginResult()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Legger til attachment
     * Implementert hovedsakelig i *ewRestResourceV1SafiMessageAttachments*::*create*
     * bpRestRequestJsonResponse
     */
    static postV1SafiMessagesAttachmentsUsingMessageid(messageID, __DATA) {
        return this._post('/v1/safi/messages/{messageID}/attachments', { messageID }, null, this._formData(__DATA), SafiAttachment.classOf(SafiAttachment, new SafiAttachment()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Fjerner tilkoblet bruker
     * Implementert hovedsakelig i *ewRestResourceV1SafiUserRemove*::*create*
     */
    static postV1SafiUserRemove() {
        return this._post('/v1/safi/user/remove', {}, null, null, APIRestClient.Native.string(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Opprett en oppgave
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*createTask*
     */
    static postV1SafiWorkplanningTasks(__DATA) {
        return this._post('/v1/safi/workplanning/tasks', {}, null, JSON.stringify(__DATA), WorkplanningTask.classOf(WorkplanningTask, new WorkplanningTask()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Assign tasks automatically
     * Implementert hovedsakelig i *ewRestResourceV1WorkPlanning*::*autoAssign*
     */
    static postV1SafiWorkplanningTasksAutoassign(__QUERY) {
        return this._post('/v1/safi/workplanning/tasks/autoassign', {}, __QUERY, null, WorkplanningAutoAssignResponse.classOf(WorkplanningAutoAssignResponse, new WorkplanningAutoAssignResponse()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Setter en avtale som valgt
     * Implementert hovedsakelig i *ewRestResourceV1AgreementsSelect*::*update*
     */
    static putV1SafiAgreementsSelectUsingAgreementid(agreementID) {
        return this._put('/v1/safi/agreements/{agreementID}/select', { agreementID }, null, null, SafiAgreement.classOf(SafiAgreement, new SafiAgreement()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Oppdatere meldingsstatus
     * Implementert hovedsakelig i *ewRestResourceV1SafiConversationMessages*::*update*
     */
    static putV1SafiConversationsMessagesStatusUsingConversationid(conversationID, __QUERY, __DATA) {
        return this._put('/v1/safi/conversations/{conversationID}/messages/status', { conversationID }, __QUERY, JSON.stringify(__DATA), APIRestClient.Native.boolean(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}
